// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#content {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.loader_content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  left: 0;
  top: 0;
  background-color: #0000005e;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  z-index: 100;
}

.loader {
  width: 4em;
  height: 4em;
  border: 0.5em solid rgba(0, 0, 0, 0.1);
  border-left-color: #3f55d1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

audio {
  width: 100%;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/main.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,OAAO;EACP,MAAM;EACN,2BAA2B;EAC3B,kCAA0B;UAA1B,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,UAAU;EACV,WAAW;EACX,sCAAsC;EACtC,0BAA0B;EAC1B,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":["#content {\n  min-height: 100vh;\n  display: grid;\n  grid-template-rows: auto 1fr auto;\n}\n\n.loader_content {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  width: 100%;\n  min-height: 100vh;\n  left: 0;\n  top: 0;\n  background-color: #0000005e;\n  backdrop-filter: blur(2px);\n  z-index: 100;\n}\n\n.loader {\n  width: 4em;\n  height: 4em;\n  border: 0.5em solid rgba(0, 0, 0, 0.1);\n  border-left-color: #3f55d1;\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  to {\n    transform: rotate(360deg);\n  }\n}\n\naudio {\n  width: 100%;\n  margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
