import React from 'react';

const ErrorPage = () => {
    return (
        <div className='text-center'>
            404 | Page Not Found!
        </div>
    );
}

export default ErrorPage;
