import axios from 'axios';
import { Button, Label, TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { displayErrors } from '../../Util';

const UserCreate = ({ setLoader }) => {
    const [name, setName] = useState(null)
    const [username, setUsername] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Create new admin"
        if (!localStorage.getItem('access_token')) {
            navigate('/login')
        }
    }, [])

    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
    const CreateUser = async () => {

        let data = { name, username }
        setLoader(true)
        await axios.post('https://jicp.jafton.com/api/users/create', data)
            .then((res) => {
                toast.success("Admin created successfully!")
                navigate('/users')
                setLoader(false)
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
    }

    return (
        <>
            <h5 className="mb-8 text-2xl text-center font-bold text-gray-900 dark:text-white">
                Create a New Admin
            </h5>
            <form className="flex max-w-md flex-col gap-4 mx-auto">
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="name" value="Name:" />
                    </div>
                    <TextInput id="name" type="text" value={name} onChange={(e) => { setName(e.target.value) }} />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="username" value="Username:" />
                    </div>
                    <TextInput id="username" type="text" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                </div>
                <Button gradientDuoTone="purpleToBlue" onClick={CreateUser}>Create</Button>
                <Link className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" to={'/users'}>
                    <p>Back</p>
                </Link>
            </form>

        </>
    );
}

export default UserCreate;
