import { Card } from 'flowbite-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate()
    useEffect(() => {
        document.title = "Home"
        if (!localStorage.getItem('access_token')) {
            navigate('/login')
        }
    }, [])
    return (
        <div>
            <Card>
                <h5 className="mb-2 text-3xl font-bold text-gray-900 dark:text-white">
                    Welcome to Jafton
                </h5>
                <p className="mb-5 text-base text-gray-500 dark:text-gray-400 sm:text-lg">
                    Welcome to the Jafton Sales Bot admin panel! In the upper right corner, you'll find navigation tabs that provide easy access to various sections, such as Home, Conclusios, Bot Settings, and Administrative functions. <b>Before gaining access, please contact your supervisor for your login credentials.</b> Within this panel, you can finely tune the bot's behavior, edit instruction sets (prompts), review database values, and more. Additionally, you have the ability to manage system administrators, whether it's creating new profiles, removing existing ones, or modifying their details. This comprehensive control over the bot's functionality and settings empowers you to easily tailor and oversee the bot according to your requirements.
                </p>
            </Card>
        </div>
    );
}

export default Home;
