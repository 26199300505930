import React from 'react';


function Files({ setLoader }) {

    return (
        <>
            <h5 className="mb-8 text-2xl text-center font-bold text-gray-900 dark:text-white">
                Files
            </h5>
            <div>

            </div>
        </>
    );
}

export default Files;