import axios from 'axios';
import { Button, Label, TextInput } from 'flowbite-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { displayErrors } from '../../Util';

const Login = ({ setLoader }) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    const submit = async (e) => {
        e.preventDefault()

        let data = {
            username: username,
            password: password,
        }

        // axios.defaults.headers.common = { 'Authorization': `${null}` }
        setLoader(true)
        await axios.post("https://jicp.jafton.com/api/auth/login", data)
            .then((res) => {
                localStorage.setItem("access_token", res.data.token)
                navigate('/')
                setLoader(false)
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
    }

    return (
        <>
            <h5 className="mb-2 text-2xl text-center font-bold text-gray-900 dark:text-white">
                Login
            </h5>
            <form className="flex max-w-md flex-col gap-4 mx-auto">
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="username" value="Username:" />
                    </div>
                    <TextInput id="username" required type="text" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="password" value="Password:" />
                    </div>
                    <TextInput id="password" required type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                </div>
                <Button onClick={submit}>Login</Button>
            </form>
        </>
    );
}

export default Login;
