import axios from 'axios';
import { Accordion, Card, Pagination } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { displayErrors } from '../../Util';
import { useNavigate } from 'react-router-dom';

const Conclusions = ({ setLoader }) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const [currentPage, setCurrentPage] = useState(1);
    const onPageChange = (page) => {
        scrollToTop()
        setCurrentPage(page)
    };
    const [conclusions, setConclusions] = useState([])
    const navigate = useNavigate()

    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
    const getConclusions = async () => {
        setLoader(true)
        await axios.get('https://jicp.jafton.com/api/client_project/get_projects/')
            .then((res) => {
                setConclusions(res.data);
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
        setLoader(false)
    }

    useEffect(() => {
        document.title = "Leads"
        if (!localStorage.getItem('access_token')) {
            navigate('/login')
        }
        getConclusions()
    }, [])

    const convertHtml = (id, text) => {
        setTimeout(() => {
            document.getElementById(id).innerHTML = " ";
            if (document.getElementById(id)) {
                let newText = text.replace(new RegExp("Lead:", 'gi'), `<br/><b>Lead:</b>`)
                newText = newText.replace(new RegExp("Bot:", 'gi'), `<br/><b>Bot:</b>`)
                newText = newText.replace('<br/><b>Lead:</b>', `<b>Lead:</b>`)
                document.getElementById(id).innerHTML = newText
            }
        }, 500)
    }

    const convertDate = (timestamp) => {
        const date = new Date(timestamp);
        const dateString = date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
        return dateString
    }

    const convertDate1000 = (timestamp) => {
        const milliseconds = timestamp * 1000;
        const date = new Date(milliseconds);
        const dateString = date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
        return dateString
    }

    return (
        <>
            <h5 className="mb-8 text-2xl text-center font-bold text-gray-900 dark:text-white">
                Leads
            </h5>
            {/* <Card className='mb-5'>
                <div className='grid lg:grid-cols-2 md:grid-cols-1 gap-4'>
                    <div>
                        <div className="mb-3 block">
                            <Label className='text-md' htmlFor="name" value="Filter by name:" />
                        </div>
                        <TextInput sizing={'base'} id="name" type="text" />
                    </div>
                    <div>
                        <div className="mb-3 block">
                            <Label className='text-md' htmlFor="date" value="Filter by date:" />
                        </div>
                        <TextInput sizing={'base'} id="date" type="date" />
                    </div>
                </div>
            </Card> */}
            {
                conclusions.length ?
                    conclusions.slice((currentPage * 10 - 10), (currentPage * 10)).map((item, index) => {
                        {
                            if (item.chat_history.content) {
                                convertHtml(`chat${item.id}`, item.chat_history.content)
                            } else {
                                setTimeout(() => {
                                    document.getElementById(`chat${item.id}`).innerHTML = " ";
                                    for (let i = 0; i < item.chat_history.length; i++) {
                                        document.getElementById(`chat${item.id}`).innerHTML += "<div class='border-2 rounded-lg p-4 mb-4 dark:border-current'>" +
                                            `<p class="text-gray-500 dark:text-gray-500 sm:text-lg text-end lg:text-base">${convertDate1000(item.chat_history[i]['timestamp'])}</p>` +
                                            `<p><b>${item.chat_history[i].role}: </b>${item.chat_history[i].content}</p>` +
                                            (item.chat_history[i].audio_url !== null ?
                                                `<audio src="${item.chat_history[i].audio_url}" controls></audio>` + "<div/>"
                                                : "")
                                    }
                                }, 500)
                            }
                        }
                        return <Card className='mb-5'>
                            <p className='text-gray-500 dark:text-gray-500 sm:text-lg text-end lg:text-base'>{convertDate(item.created_at)}</p>
                            <div className='grid grid-cols-2 gap-4'>
                                <div>
                                    <p className='text-gray-900 dark:text-gray-300 sm:text-lg lg:text-base lg:leading-8'>Name: <b>{item.name}</b></p>
                                    <p className='text-gray-900 dark:text-gray-300 sm:text-lg lg:text-base lg:leading-8'>Contact info: <b>{item.contact_info}</b></p>
                                    <p className='text-gray-900 dark:text-gray-300 sm:text-lg lg:text-base lg:leading-8'>Budget: <b>${item.budget.toLocaleString('en-US')}</b></p>
                                </div>
                                <div>
                                    <p className='text-gray-900 dark:text-gray-300 sm:text-lg lg:text-base lg:leading-8'>Contract: <b>{item.contract_type ? item.contract_type : '------------------------'}</b></p>
                                    <p className='text-gray-900 dark:text-gray-300 sm:text-lg lg:text-base lg:leading-8'>Short description: <b>{item.description}</b></p>
                                </div>
                            </div>
                            <Accordion collapseAll>
                                <Accordion.Panel>
                                    <Accordion.Title>Chat history</Accordion.Title>
                                    <Accordion.Content>
                                        <p id={`chat${item.id}`} className='text-gray-700 dark:text-gray-400 sm:text-lg lg:text-base lg:leading-7'></p>
                                    </Accordion.Content>
                                </Accordion.Panel>
                            </Accordion>
                        </Card>
                    })
                    : <p className='text-gray-500 dark:text-gray-300 sm:text-lg text-center'>Leads are empty</p>
            }
            <div className="flex overflow-x-auto sm:justify-center">
                <Pagination currentPage={currentPage} totalPages={(Math.ceil(conclusions.length / 10) * 10) / 10} onPageChange={onPageChange} />
            </div>
        </>
    );
}

export default Conclusions;
