import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Label, RangeSlider, Select, Textarea } from 'flowbite-react';
import axios from 'axios';
import { displayErrors } from '../../Util';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { data } from 'autoprefixer';

const Prompt = ({ setLoader }) => {
    const [prompt, setPrompt] = useState(null)
    const [temperature, setTemperature] = useState(1)
    const [model, setModel] = useState('')
    const navigate = useNavigate()
    const models = ['gpt-3.5-turbo', 'gpt-3.5-turbo-16k', 'gpt-3.5-turbo-instruct', 'gpt-3.5-turbo-0613', 'gpt-3.5-turbo-16k-0613', 'gpt-3.5-turbo-0301', 'gpt-3.5-turbo-1106', 'text-davinci-003', 'text-davinci-002', 'code-davinci-002', 'gpt-4', 'gpt-4-0613', 'gpt-4-32k', 'gpt-4-32k-0613', 'gpt-4-0314', 'gpt-4-32k-0314', 'gpt-4-1106-preview']

    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }

    const getConfigs = async () => {
        setLoader(true)
        await axios.get('https://jicp.jafton.com/api/config/')
            .then((res) => {
                if (res.data) {
                    setPrompt(res.data.prompt);
                    setTemperature(res.data.temperature);
                    setModel(res.data.model);
                }
                else {
                    setPrompt(' ');
                    setTemperature(1);
                    setModel('gpt-3.5-turbo');
                }
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
        setLoader(false)
    }

    const editConfigs = async () => {
        setLoader(true)
        let request_data = { prompt: prompt, temperature: +temperature, model: model }
        await axios.put('https://jicp.jafton.com/api/config/edit', request_data)
            .then((res) => {
                toast.success("Settings updated successfully!")
                setLoader(false)
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
    }

    useEffect(() => {
        document.title = "Bot settings"
        if (!localStorage.getItem('access_token')) {
            navigate('/login')
        }
        getConfigs()
    }, [])

    return (
        <>
            <h5 className="mb-8 text-2xl text-center font-bold text-gray-900 dark:text-white">
                Bot settings
            </h5>
            <div className='grid lg:grid-cols-2 md:grid-cols-1 gap-4'>
                <div>
                    <Card>
                        <div className="mb-3 block">
                            <Label htmlFor="models" className='text-lg'>Prompt:</Label>
                        </div>
                        <Textarea placeholder="Enter the prompt..." rows={20} value={prompt} onChange={(e) => setPrompt(e.target.value)} />
                    </Card>
                </div>

                <div>
                    <Card>
                        <div>
                            <div className="mb-3 block">
                                <Label htmlFor="lg-range" className='text-lg flex'>Temperature: <Badge size={'lg'} className='ml-2' color="indigo">{temperature}</Badge></Label>
                            </div>
                            <RangeSlider value={temperature} max={1} min={0.1} step={0.1} id="lg-range" sizing="lg" onChange={(e) => setTemperature(e.target.value)} />
                        </div>
                    </Card>
                    <Card className='mt-5'>
                        <div>
                            <div className="mb-3 block">
                                <Label htmlFor="models" className='text-lg'>Model:</Label>
                            </div>
                            <Select id="models" sizing={'lg'} onChange={(e) => setModel(e.target.value)}>
                                {
                                    model ?
                                        models.map((item) => {
                                            let selected = model === item ? true : false;
                                            return <option selected={selected}>{item}</option>
                                        })
                                        : null
                                }
                            </Select>
                        </div>
                    </Card>
                </div>
            </div>
            <div className='mt-5 flex justify-end'>
                <Button gradientDuoTone="purpleToBlue" size={'xl'} onClick={editConfigs}>
                    Save
                </Button>
            </div>
        </>
    );
}

export default Prompt;
