import axios from 'axios';
import { Alert, Button, Modal } from 'flowbite-react';
import React from 'react';
import { useState } from 'react';
import { FaUnlockAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { displayErrors } from '../../Util';

const GeneratePasswordModal = ({ id, name, username, setLoader }) => {
    const [openModal, setOpenModal] = useState(false);
    function generatePassword() {
        const passwordChars = [];
        const letters = "abcdefghijklmnopqrstuvwxyz"
        const numbers = "0123456789"
        for (let i = 0; i < 4; i++) {
            const randomIndex = Math.floor(Math.random() * letters.length);
            passwordChars.push(letters[randomIndex]);
        }
        for (let i = 0; i < 4; i++) {
            const randomIndex = Math.floor(Math.random() * numbers.length);
            passwordChars.push(numbers[randomIndex]);
        }
        return passwordChars.join("");
    }

    const getPasssword = () => {
        const password_content = document.querySelector(`#password_content${id}`)
        if (password_content) {
            password_content.textContent = generatePassword()
        }
    }

    const closeModal = () => {
        const password_content = document.querySelector(`#password_content${id}`)
        if (password_content) {
            password_content.textContent = '********'
        }
        setOpenModal(false)
    }

    function copyToClipboard() {
        const password_content = document.querySelector(`#password_content${id}`)
        navigator.clipboard.writeText(password_content.textContent);
        toast.success("Copied to the clipboard!")
    }

    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
    const setPassword = async () => {
        setLoader(true)
        const password_content = document.querySelector(`#password_content${id}`)
        let data = { user_id: id, password: password_content.textContent }

        await axios.post('https://jicp.jafton.com/api/users/generate/password', data)
            .then((res) => {
                toast.success("Password changed successfully!")
                closeModal()
                setLoader(false)
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
    }

    return (
        <>
            <Button color='blue' onClick={() => setOpenModal(true)}> <FaUnlockAlt /></Button>
            <Modal show={openModal} onClose={() => closeModal()}>
                <Modal.Header>Generate new password for "{name}"</Modal.Header>
                <Modal.Body>
                    <div className="flex justify-between items-center">
                        <Button className="w-full" color="light" onClick={()=>copyToClipboard()}>
                            <b id={`password_content${id}`}>********</b>
                        </Button>
                        <Button gradientDuoTone="purpleToBlue" className="ml-3" onClick={() => getPasssword()}>Generate</Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button gradientDuoTone="purpleToBlue" onClick={() => setPassword()}>Save</Button>
                    <Button color="gray" onClick={() => closeModal()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default GeneratePasswordModal;
