import axios from 'axios';
import { Button, Card, Label, TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { displayErrors } from '../../Util';

const UserDelete = ({ setLoader }) => {
    const [name, setName] = useState(null)
    const [username, setUsername] = useState(null)

    const params = useParams()

    const navigate = useNavigate()

    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }


    const getUser = async () => {
        setLoader(true)
        await axios.get(`https://jicp.jafton.com/api/users/detail/${params.id}`)
            .then((res) => {
                setName(res.data.name)
                setUsername(res.data.username)
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
        setLoader(false)
    }


    const DeleteUser = async () => {
        setLoader(true)
        await axios.delete(`https://jicp.jafton.com/api/users/delete/${params.id}`)
            .then((res) => {
                toast.success("Admin deleted successfully!")
                navigate('/users')
                setLoader(false)
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
    }

    useEffect(() => {
        document.title = "Delete admin"
        if (!localStorage.getItem('access_token')) {
            navigate('/login')
        }
        getUser()
    }, [])
    return (
        <>
            <div className='flex justify-center'>
                <Card className="max-w-sm">
                    <h6 className="mb-8 text-2xl text-center font-bold text-red-600 dark:text-red-500">
                        Delete the admin named "{name}"
                    </h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <Link to={'/users'}>
                            <Button color="blue">Back</Button>
                        </Link>
                        <Button onClick={DeleteUser} color='failure'>Delete</Button>
                    </div>
                </Card>
            </div>

        </>
    );
}

export default UserDelete;
