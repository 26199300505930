import axios from 'axios';
import { Button, Label, TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { displayErrors } from '../../Util';

const UserEdit = ({ setLoader }) => {
    const [name, setName] = useState(null)
    const [username, setUsername] = useState(null)

    const params = useParams()

    const navigate = useNavigate()

    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }


    const getUser = async () => {
        setLoader(true)
        await axios.get(`https://jicp.jafton.com/api/users/detail/${params.id}`)
            .then((res) => {
                setName(res.data.name)
                setUsername(res.data.username)
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
        setLoader(false)
    }


    const EditUser = async () => {

        let data = { name, username }
        setLoader(true)
        await axios.put(`https://jicp.jafton.com/api/users/edit/${params.id}`, data)
            .then((res) => {
                toast.success("Admin edited successfully!")
                navigate('/users')
                setLoader(false)
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
    }

    useEffect(() => {
        document.title = "Edit admin"
        if (!localStorage.getItem('access_token')) {
            navigate('/login')
        } getUser()
    }, [])

    return (
        <>
            <h5 className="mb-8 text-2xl text-center font-bold text-gray-900 dark:text-white">
                Edit admin
            </h5>
            <form className="flex max-w-md flex-col gap-4 mx-auto">
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="name" value="Name:" />
                    </div>
                    <TextInput id="name" type="text" value={name} onChange={(e) => { setName(e.target.value) }} />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="username" value="Username:" />
                    </div>
                    <TextInput id="username" type="text" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                </div>
                <Button gradientDuoTone="purpleToBlue" onClick={EditUser}>Save</Button>
                <Link className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" to={'/users'}>
                    <p>Back</p>
                </Link>
            </form>

        </>
    );
}

export default UserEdit;
