import React, { useEffect, useState } from 'react';
import MyNavbar from './Components/MyNavbar';
import Login from './Pages/Auth/Login';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Pages/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorPage from './Pages/ErrorPage';
import Prompt from './Pages/Settings/Settings';
import Users from './Pages/Users/Users';
import UserCreate from './Pages/Users/UserCreate';
import MyFooter from './Components/MyFooter';
import UserEdit from './Pages/Users/UserEdit';
import UserDelete from './Pages/Users/UserDelete';
import Conclusions from './Pages/Conclusions/Conclusions';
import Loader from './Components/Loader';
import Emails from './Pages/Email/Emails';
import EmailCreate from './Pages/Email/EmailCreate';
import EmailEdit from './Pages/Email/EmailEdit';
import EmailDelete from './Pages/Email/EmailDelete';
import Files from './Pages/Files/Files';

const App = () => {
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      navigate('/login')
    }
  }, [])
  return (
    <div id='content'>
      <MyNavbar />
      <div className='container mx-auto px-4 py-5'>
        {
          loader ? <Loader /> : null
        }
        <Routes>
          <Route path='/settings' element={<Prompt setLoader={setLoader} />} />
          <Route path='/emails' element={<Emails setLoader={setLoader} />} />
          <Route path='/emails/create' element={<EmailCreate setLoader={setLoader} />} />
          <Route path='/emails/edit/:id' element={<EmailEdit setLoader={setLoader} />} />
          <Route path='/emails/delete/:id' element={<EmailDelete setLoader={setLoader} />} />
          <Route path='/users' element={<Users setLoader={setLoader} />} />
          <Route path='/users/create' element={<UserCreate setLoader={setLoader} />} />
          <Route path='/users/edit/:id' element={<UserEdit setLoader={setLoader} />} />
          <Route path='/users/delete/:id' element={<UserDelete setLoader={setLoader} />} />
          <Route path='/leads' element={<Conclusions setLoader={setLoader} />} />
          <Route path='/login' element={<Login setLoader={setLoader} />} />
          <Route path='/' element={<Home />} />
          <Route path='*' element={<ErrorPage />} />
          {/* <Route path='/files' element={<Files setLoader={setLoader} />} /> */}
        </Routes>
        <ToastContainer />
      </div>
      <MyFooter />
    </div>
  );
}

export default App;
