import { Button, Label, TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { displayErrors } from '../../Util';
import axios from 'axios';

const EmailCreate = ({setLoader}) => {
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Add new email"
        if (!localStorage.getItem('access_token')) {
            navigate('/login')
        }
    }, [])

    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
    const CreateEmail = async () => {

        let data = { name, email }
        setLoader(true)
        await axios.post('https://jicp.jafton.com/api/emails/create', data)
            .then((res) => {
                toast.success("Email created successfully!")
                navigate('/emails')
                setLoader(false)
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
    }
    return (
        <>
            <h5 className="mb-8 text-2xl text-center font-bold text-gray-900 dark:text-white">
                Create a New Email
            </h5>
            <form className="flex max-w-md flex-col gap-4 mx-auto">
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="name" value="Name:" />
                    </div>
                    <TextInput id="name" type="text" value={name} onChange={(e) => { setName(e.target.value) }} />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="email" value="Email:" />
                    </div>
                    <TextInput id="email" type="text" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                </div>
                <Button gradientDuoTone="purpleToBlue" onClick={CreateEmail}>Create</Button>
                <Link className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" to={'/emails'}>
                    <p>Back</p>
                </Link>
            </form>
        </>
    );
}

export default EmailCreate;
