import { toast } from "react-toastify";
import emailjs from 'emailjs-com';


export const handleError = (error) => {

    const emailParams = {
        from_name: 'Jafton Sales AI Bot',
        from_email: 'quvonchbe05@gmail.com',
        to_name: 'Developers',
        to_email: 'quvonchbe05@gmail.com',
        subject: 'Error in Jafton Sales AI Bot',
        message: error,
    };

    emailjs.send('service_m0m9zdg', 'template_505jw0e', emailParams, '9WW7L-6HME9az-9-N')
        .then(() => {
            toast.success("The bug has been reported to the developers!")
        }, (error) => {
            console.error('Error sending email:', error);
        });
};


export const displayErrors = (error) => {
    if (error.response.status == 500) {
        handleError('Internal server error!')
    }
    if (error.response.status == 502) {
        handleError('Сервер упал!')
    }
    let response_errors = Object.keys(error.response.data)
    console.log(error.response.data.detail);
    if (Array.isArray(error.response.data.detail)) {
        for (let i = 0; i < response_errors.length; i++) {
            for (let e = 0; e < error.response.data.detail.length; e++) {
                let data = error.response.data.detail[e]
                console.log(data);
                toast.error(`${data.loc[1].toUpperCase()}: ${data.msg}`)
            }
        }
    } else {
        toast.error(`${error.response.data.detail}`)
    }
}


