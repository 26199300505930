import React from 'react';
import Img from '../assets/img/logo_jafton.svg'
import { DarkThemeToggle, Navbar } from 'flowbite-react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const MyNavbar = () => {
    const navigate = useNavigate()

    const logout = async () => {
        localStorage.removeItem('access_token');
        navigate('/login')
        toast.success("You are logged out!");
    }

    function parseJwt(token) {
        if (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        }
        else return null
    }

    const user_from_token = parseJwt(localStorage.getItem('access_token'))


    return (
        <Navbar fluid rounded className='m-2 drop-shadow-md'>

            <div className='flex items-center'>
                <Link to={'/'} className='flex items-center'>
                    <img alt="Logo" className="mr-3 h-6 sm:h-9" src={Img} />
                    {/* <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white"></span> */}
                </Link>
                <DarkThemeToggle className='ml-3' />
            </div>

            <Navbar.Toggle />

            <Navbar.Collapse>

                {
                    localStorage.getItem('access_token') ?
                        <>

                            <Link to={'/'} className='block py-2 pl-3 pr-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>Home</Link>

                            <Link to={'/leads'} className='block py-2 pl-3 pr-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>Leads</Link>
                            
                            <Link to={'/emails'} className='block py-2 pl-3 pr-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>Emails</Link>
                            
                            <Link to={'/files'} className='block py-2 pl-3 pr-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>Files</Link>

                            <Link to={'/settings'} className='block py-2 pl-3 pr-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>Bot settings</Link>

                            {
                                user_from_token.is_admin == true ?
                                    <Link to={'/users'} className='block py-2 pl-3 pr-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>Admins</Link>
                                    : null
                            }

                            <button onClick={logout} className='block py-2 pl-3 pr-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>Exit</button>

                        </> :
                        <>

                            <Link to={'/login'} className='block py-2 pl-3 pr-4 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>Login</Link>

                        </>
                }

            </Navbar.Collapse>
        </Navbar>
    );

}
export default MyNavbar;
