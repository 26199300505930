import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { displayErrors } from '../../Util';
import { Button, Table } from 'flowbite-react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUnlockAlt } from 'react-icons/fa'
import { BsPencilSquare, BsTrashFill } from 'react-icons/bs'
import GeneratePasswordModal from './GeneratePasswordModal';

const Users = ({ setLoader }) => {
    const [users, setUsers] = useState(null)
    const navigate = useNavigate()

    function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    const user_from_token = parseJwt(localStorage.getItem('access_token'))

    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }

    const getUsers = async () => {
        setLoader(true)
        await axios.get('https://jicp.jafton.com/api/users/list')
            .then((res) => {
                setUsers(res.data);
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
        setLoader(false)
    }

    useEffect(() => {
        document.title = "Admins"
        getUsers()
        if (user_from_token.is_admin != true) {
            navigate('/home')
        }
        if (!localStorage.getItem('access_token')) {
            navigate('/login')
        }
    }, [])

    const generatePassword = async (id) => {
        let password = null
        await axios.get(`https://jicp.jafton.com/api/users/generate/password/${id}`)
            .then((res) => {
                password = res.data.password
            })
            .catch((error) => {
                displayErrors(error)
                setLoader(false)
            })
        return password
    }

    return (
        <>
            <h5 className="mb-8 text-2xl text-center font-bold text-gray-900 dark:text-white">
                Admins
            </h5>
            <div>
                <Table>
                    <Table.Head>
                        <Table.HeadCell>
                            №
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Name
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Username
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <Link to={'/users/create'}>
                                <Button gradientDuoTone="purpleToBlue" size='sm'>
                                    CREATE A NEW ADMIN
                                </Button>
                            </Link>
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {
                            users ?
                                users.map((user, index) => {
                                    return (
                                        <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                {index + 1}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {user.name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {user.username}
                                            </Table.Cell>
                                            <Table.Cell className='flex gap-2'>
                                                <GeneratePasswordModal id={user.id} name={user.name} username={user.username} setLoader={setLoader} />
                                                <Link to={`/users/edit/${user.id}`}>
                                                    <Button color='indigo'>
                                                        <BsPencilSquare />
                                                    </Button></Link>
                                                {
                                                    user_from_token.username == user.username ?
                                                        <Button color='failure' disabled>
                                                            <BsTrashFill />
                                                        </Button>
                                                        : <Link to={`/users/delete/${user.id}`}>
                                                            <Button color='failure' >
                                                                <BsTrashFill />
                                                            </Button>
                                                        </Link>
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }) : null
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    );
}

export default Users;
