import axios from 'axios';
import { Button, Table } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { BsPencilSquare, BsTrashFill } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';

const Emails = ({ setLoader }) => {
    const [emails, setEmails] = useState([])
    const navigate = useNavigate()

    axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }

    const getEmails = async () => {
        setLoader(true)
        await axios.get('https://jicp.jafton.com/api/emails/list')
            .then((res) => {
                setEmails(res.data);
            })
            .catch((error) => {
                setEmails(error)
            })
        setLoader(false)
    }

    useEffect(() => {
        document.title = "Emails"
        getEmails()
        if (!localStorage.getItem('access_token')) {
            navigate('/login')
        }
    }, [])

    console.log(emails);

    return (
        <>
            <h5 className="mb-8 text-2xl text-center font-bold text-gray-900 dark:text-white">
                Emails
            </h5>
            <div>
                <Table>
                    <Table.Head>
                        <Table.HeadCell>
                            №
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Name
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Email
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <Link to={'/emails/create'}>
                                <Button gradientDuoTone="purpleToBlue" size='sm'>
                                    ADD A NEW EMAIL
                                </Button>
                            </Link>
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {
                            emails ?
                                emails.map((email, index) => {
                                    return (
                                        <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                {index + 1}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {email.name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {email.email}
                                            </Table.Cell>
                                            <Table.Cell className='flex gap-2'>
                                                <Link to={`/emails/edit/${email.id}`}>
                                                    <Button color='indigo'>
                                                        <BsPencilSquare />
                                                    </Button></Link>
                                                <Link to={`/emails/delete/${email.id}`}>
                                                    <Button color='failure' >
                                                        <BsTrashFill />
                                                    </Button>
                                                </Link>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }) : null
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    );
}

export default Emails;
